import * as Yup from 'yup'
import { axios } from 'api'

const initializeRedemption = (dispatch, { offer, redemptionCode }) => ({
  type: 'redemption',
  loadError: null,
  state: {
    ...offer
  },
  values: {
    redemptionCode: redemptionCode || '',
    redemptionCodeSet: !!redemptionCode
  },
  validators: {
    redemptionCodeSet: Yup.boolean(),
    redemptionCode: Yup.string().required(
      'Please enter a valid redemption code'
    )
  },
  actions: {
    checkOfferCode: async ({ values, state }) => {
      try {
        // Ruby expects the params to be in snake_case, so here we are.
        const res = await axios.get(
          `redemption_offers/${
            state.redemption.slug
          }/codes/${values.redemptionCode.trim()}`
        )
        if (res) {
          return res // return res if succesful
        }
      } catch (error) {
        throw {
          field: 'redemptionCode',
          message:
            'That redemption code does not exist or has already been claimed.'
        }
      }
    }
  }
})

export default initializeRedemption
